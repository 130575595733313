import { CircularProgress } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SetService } from "../../services/set-service";
import { SetTemplate } from "../../templates/set-template";

enum StudyVal {
  primaryVal,
  secondaryVal,
}

interface TermDropLocation {
  term: string;
  index: number;
}

export default function TranslationPractice() {
  const { setName } = useParams();
  const [setData, setSetData] = useState<SetTemplate[]>([]);
  const [activeTerms, setActiveTerms] = useState<string[]>([]);
  const [sentences, setSentences] = useState<string[]>([]);
  const [progressDenom, setProgressDenom] = useState<number>(1);
  const [progressVal, setProgressVal] = useState<number>(0);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState<number>(0);
  const [doneStudying, setDoneStudying] = useState<boolean>(false);
  const [answerInput, setAnswerInput] = useState<string>("");
  const [termDropLocations, setTermDropLocations] = useState<
    TermDropLocation[]
  >([]);
  const [valToUse, setValToUse] = useState<StudyVal>(StudyVal.secondaryVal);
  const [creatingSentences, setCreatingSentences] = useState<boolean>(true);
  const [dragTerm, setDragTerm] = useState<string>("");
  const [dragDropLocation, setDragDropLocation] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);

  const setService = useMemo(() => new SetService(), []);
  const navigate = useNavigate();

  const DISPLAYED_TERMS_LIMIT = 20;
  const TERM_DROP_LOCATIONS_LIMIT = 10;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") onInputClick();
  };

  const getNewWordBank = useCallback(
    (array: SetTemplate[], valToUsePassed?: StudyVal): string[] => {
      const newWords: string[] = [];
      const set = shuffleArray(array);
      const funcValToUse = valToUsePassed ?? valToUse;
      for (let i = 0; i < Math.min(DISPLAYED_TERMS_LIMIT, set.length); i++) {
        newWords.push(
          (funcValToUse === StudyVal.primaryVal
            ? set[i].primaryVal
            : set[i].secondaryVal) ?? "Value Missing"
        );
      }
      return newWords;
    },
    [valToUse]
  );

  useEffect(() => {
    if (setName !== undefined && setData.length === 0) {
      emptyDropLocations();
      const set: SetTemplate[] = setService.getSet(setName);
      setSetData(set);
      setActiveTerms(getNewWordBank(set));
      setLoading(false);
    }
  }, [setName, setService, setData.length, getNewWordBank]);

  function emptyDropLocations() {
    const emptyDropLocations = [];
    for (let i = 0; i < TERM_DROP_LOCATIONS_LIMIT; i++) {
      emptyDropLocations.push({
        term: "",
        index: i,
      } as TermDropLocation);
    }
    setTermDropLocations(emptyDropLocations);
  }

  function shuffleArray(array: any[]) {
    var m = array.length,
      t,
      i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  const dragWordStart = (e: any) => {
    setDragTerm(e.target.innerHTML);
  };

  const dragEnter = (e: any, position: number) => {
    setDragDropLocation(position);
  };

  const drop = (e: any) => {
    if (dragTerm === "" || dragDropLocation === -1) return;
    const dropLocations = termDropLocations;
    dropLocations[dragDropLocation].term = dragTerm;
    setTermDropLocations(dropLocations);
    setDragTerm("");
    setDragDropLocation(-1);
  };

  function drawWordChoices(): JSX.Element {
    const words: JSX.Element[] = [];
    activeTerms.forEach((item, i) => {
      words.push(
        <div
          draggable={true}
          onDragStart={(e) => dragWordStart(e)}
          onDragEnd={drop}
          key={"drag" + i}
          className="cursor-pointer m-1 p-1 bg-rose-800/[.75] rounded drop-shadow-lg"
        >
          {item}
        </div>
      );
    });
    return <div className="flex flex-wrap m-5">{words}</div>;
  }

  function drawWordLocations(): JSX.Element[] {
    const locations: JSX.Element[] = [];
    termDropLocations.forEach((item, i) => {
      locations.push(
        <div
          onDragEnter={(e) => dragEnter(e, i)}
          onClick={() => {}}
          key={"drop" + i}
          className="h-8 flex flex-row m-1 p-1 bg-rose-800/[.75] rounded drop-shadow-lg"
        >
          <div className="mr-1">{item.index + 1}.</div>
          {item.term === "" ? (
            <div className="w-16 border-b-[1px]"></div>
          ) : (
            <div>{item.term}</div>
          )}
        </div>
      );
    });
    return locations;
  }

  function nextSentence() {
    let sentence = "";
    const newSentences = sentences;
    termDropLocations.forEach((item) => {
      if (item.term !== "") sentence += item.term + ", ";
    });
    sentence = sentence.substring(0, sentence.length - 2);
    if (sentence === "") return;
    newSentences.push(sentence);
    setSentences(newSentences);
    emptyDropLocations();
  }

  function startStudying() {
    nextSentence();
    if (sentences.length === 0) return;
    setCreatingSentences(false);
    setCurrentSentenceIndex(0);
    setProgressDenom(0);
  }

  function onInputClick() {
    setAnswerInput("");
    setProgressDenom(progressDenom + 1);
    setProgressVal(((progressDenom + 1) / sentences.length) * 100);
    if (currentSentenceIndex + 1 < sentences.length) {
      setCurrentSentenceIndex(currentSentenceIndex + 1);
    } else {
      setDoneStudying(true);
    }
  }

  function switchValToUse() {
    const newValToUse =
      valToUse === StudyVal.primaryVal
        ? StudyVal.secondaryVal
        : StudyVal.primaryVal;
    setValToUse(newValToUse);
    setActiveTerms(getNewWordBank(setData, newValToUse));
  }

  return (
    <div className="h-screen" onKeyUp={handleKeyPress}>
      <div className="sm:block hidden">
        <button
          className="absolute top-20 sm:left-32 left-5 w-10 sm:w-36 py-2 rounded-lg bg-rose-600/[.75] drop-shadow-lg"
          onClick={() => navigate("/")}
        >
          <div className="flex flex-row justify-center sm:mr-5">
            <ChevronLeft></ChevronLeft>
            <span className="sm:visible invisible w-0 sm:w-auto">Back</span>
          </div>
        </button>
        {loading ? (
          <div className="flex flex-row py-5 justify-center">
            <CircularProgress />
            <div className="text-5xl ml-2">Loading...</div>
          </div>
        ) : (
          setName !== "" &&
          setName !== undefined && (
            <div className="flex flex-col justify-center h-full">
              <div className="w-1/2 justify-self-center self-center text-4xl mt-16 sm:mt-5 mb-5">
                <div>
                  Set: {setName.slice(0, 25)}
                  {setName.length > 25 ? "..." : ""}
                </div>
              </div>
              {creatingSentences ? (
                <>
                  <div className="lg:w-1/2 w-full bg-gray-700/[.4] rounded mx-auto drop-shadow-lg">
                    <div className="mt-6 text-lg">
                      Drag & drop on the spaces below to create a sentence
                    </div>
                    {drawWordChoices()}
                    <div className="flex flex-wrap">
                      <button
                        onClick={switchValToUse}
                        className="w-44 mr-2 mb-5 ml-auto py-2 rounded-lg bg-slate-700 drop-shadow-lg"
                      >
                        Switch Term Values
                      </button>
                      <button
                        onClick={() => setActiveTerms(getNewWordBank(setData))}
                        className="w-44 ml-2 mb-5 mr-auto py-2 rounded-lg bg-slate-700 drop-shadow-lg"
                      >
                        New Word Bank
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-10 lg:w-1/2 w-full bg-gray-700/[.4] rounded mx-auto drop-shadow-lg">
                    <div className="flex flex-wrap m-5">
                      {drawWordLocations()}
                    </div>
                  </div>
                  <div className="mt-5 justify-center my-auto mb-auto">
                    <button
                      onClick={startStudying}
                      className="w-44 mr-4 mb-5 mt-2 py-2 rounded-lg bg-slate-700 drop-shadow-lg"
                    >
                      Start Studying
                    </button>
                    <button
                      onClick={nextSentence}
                      className="w-44 mb-5 mt-2 py-2 rounded-lg bg-slate-700 drop-shadow-lg"
                    >
                      Next Sentence
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="h-2.5 bg-black/[.7] w-1/2 mx-auto mb-5 rounded-xl">
                    <div
                      className="bg-rose-600 h-2.5 rounded-xl"
                      style={{
                        width: (progressVal > 1 ? progressVal : 1) + "%",
                      }}
                    ></div>
                  </div>
                  <div className="h-1/2 lg:w-1/2 w-full bg-gray-700/[.4] rounded mb-auto mx-auto drop-shadow-lg">
                    {!doneStudying ? (
                      <div className="flex flex-col justify-center">
                        <div className="text-3xl my-10 px-5">
                          {sentences[currentSentenceIndex]}
                        </div>
                        <div>
                          <input
                            className="w-1/2 rounded text-lg text-black p-1 drop-shadow-lg"
                            placeholder="Answer"
                            value={answerInput}
                            onChange={(event) =>
                              setAnswerInput(event.target.value)
                            }
                            type="text"
                          />
                          <div className="">
                            <button
                              className="md:w-1/3 w-5/12 p-1 m-1 mt-2 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                              onClick={onInputClick}
                              type="button"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div className="text-3xl my-5">Done Studying</div>
                        <button
                          className="md:w-1/3 w-7/12 p-1 mx-auto mt-2 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                          onClick={() => navigate("/home")}
                          type="button"
                        >
                          Exit
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )
        )}
      </div>
      <div className="sm:hidden">
        <div className="text-2xl mt-5">
          Translation practice is in beta and does not yet work on mobile.
        </div>
      </div>
    </div>
  );
}

import { useEffect, useMemo } from "react";
import { useState } from "react";
import { SetService } from "../../services/set-service";
import { SetNameTemplate, SetTemplate } from "../../templates/set-template";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { ViewCarousel, Notes } from "@mui/icons-material";

interface HomeSetList {
  setName: string;
  cardsToStudy: number;
  nextStudyDate: string;
  studiedToday: boolean;
}

export default function Home() {
  const [studySets, setStudySets] = useState<HomeSetList[]>([]);
  const [displayConfirmation, setDisplayConfirmation] = useState<string>("");
  const [displayStudyModal, setDisplayStudyModal] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const setService = useMemo(() => new SetService(), []);

  const maxPerDay = setService.getMaxStudyAmount();

  useEffect(() => {
    function getNumToStudyToday(set: SetTemplate[]): number {
      let count = 0;
      set.forEach((temp) => {
        if (
          (temp.dateToStudy ?? setService.getDateString()) <=
          setService.getDateString()
        ) {
          count += 1;
        }
      });
      return count;
    }

    function getNextStudyDate(
      set: SetTemplate[],
      setNameTemp: SetNameTemplate
    ): string {
      let date = "9999/99/99";
      set.forEach((temp) => {
        date =
          date > (temp.dateToStudy ?? setService.getDateString())
            ? temp.dateToStudy ?? setService.getDateString()
            : date;
      });
      if (date <= setService.getDateString()) {
        date =
          setNameTemp.lastStudied === setService.getDateString()
            ? setService.addDaysToDate(setService.getDateString(), 1)
            : setService.getDateString();
      }
      return date.substring(5);
    }

    let tempStudySets: HomeSetList[] = [];
    setService.getSetNames().forEach((temp) => {
      let setTemp = setService.getSet(temp.setName);
      tempStudySets.push({
        setName: temp.setName,
        cardsToStudy: getNumToStudyToday(setTemp),
        nextStudyDate: getNextStudyDate(setTemp, temp),
        studiedToday: temp.lastStudied === setService.getDateString(),
      });
    });
    try {
      tempStudySets = tempStudySets.sort(
        (a, b) => b.cardsToStudy - a.cardsToStudy
      );
    } catch (e) {
      console.log("Could not sort study sets:", e);
    }
    setStudySets(tempStudySets);
    setIsLoading(false);
  }, [setService]);

  function renderSetList(): JSX.Element[] {
    const renderedList: JSX.Element[] = [];
    studySets.forEach((item, i) => {
      const canStudy = item.cardsToStudy !== 0 && !item.studiedToday;
      renderedList.push(
        <div key={i} className="flex justify-center">
          <div
            onClick={() => setDisplayStudyModal(item.setName)}
            className={
              "flex flex-row p-2 m-2 w-96 border-slate-700 rounded drop-shadow-lg bg-gray-900 border-2 cursor-pointer"
            }
          >
            <div className="flex flex-col text-left w-60">
              <div className="font-bold">
                {item.setName.slice(0, 25)}
                {item.setName.length > 25 ? "..." : ""}
              </div>
              {canStudy ? (
                <div className="text-sm ml-2">
                  Cards To Study:{" "}
                  <span className="font-bold text-base">
                    {Math.min(item.cardsToStudy, maxPerDay)}
                  </span>
                </div>
              ) : (
                <div className="text-sm ml-2">
                  Next Date:{" "}
                  <span className="text-base">{item.nextStudyDate}</span>
                </div>
              )}
            </div>
            <div className="w-20"></div>
            <div className="w-16 flex flex-row drop-shadow-lg cursor-pointer">
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`/edit-set/${item.setName}`);
                }}
                className="hover:text-slate-50 h-fit"
              >
                <SettingsIcon />
              </div>
              <div className="w-4" />
              <div
                className="text-rose-600 drop-shadow-lg cursor-pointer hover:text-rose-400 h-fit"
                onClick={(event) => {
                  event.stopPropagation();
                  setDisplayConfirmation(item.setName);
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return renderedList;
  }

  function deleteSet(setName: string) {
    setService.deleteSet(displayConfirmation);
    setStudySets(studySets.filter((item) => item.setName !== setName));
    setDisplayConfirmation("");
  }

  const ConfirmDelete = (): JSX.Element => {
    return (
      <>
        {displayConfirmation !== "" && displayConfirmation !== undefined && (
          <div className="absolute h-full w-full bg-black bg-opacity-60">
            <div className="absolute top-1/4 lg:left-1/3 lg:right-1/3 left-10 right-10 h-1/3 lg:w-1/3 sm:w-1/2 z-auto m-auto bg-slate-900 rounded-xl flex flex-col drop-shadow-2xl">
              <div className="text-2xl m-10">
                Are you sure you want to delete your set, {displayConfirmation}?
              </div>
              <div className="flex flex-wrap justify-center">
                <button
                  className="w-32 py-1 rounded-lg bg-slate-700 drop-shadow-lg hover:bg-slate-600"
                  onClick={() => setDisplayConfirmation("")}
                >
                  Cancel
                </button>
                <button
                  className="ml-2 w-32 py-1 rounded-lg bg-slate-700 drop-shadow-lg hover:bg-slate-600"
                  onClick={() => deleteSet(displayConfirmation)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  function canStudySet(setName: string): boolean {
    let canStudy = false;
    studySets.forEach((item) => {
      if (item.setName === setName) {
        canStudy = item.cardsToStudy !== 0 && !item.studiedToday;
      }
    });
    return canStudy;
  }

  function getCardTotalInSet(setName: string): number {
    let cardTotal = -1;
    studySets.forEach((item) => {
      if (item.setName === setName) {
        cardTotal = setService.getSet(setName).length;
      }
    });
    return cardTotal;
  }

  const StudyModal = (): JSX.Element => {
    return (
      <>
        {displayStudyModal !== "" && displayStudyModal !== undefined && (
          <div className="absolute h-full w-full bg-black bg-opacity-60">
            <div className="absolute top-1/4 lg:left-1/3 lg:right-1/3 left-10 right-10 h-fit pb-2 lg:w-1/3 sm:w-1/2 z-auto m-auto bg-slate-900 rounded-xl flex flex-col drop-shadow-2xl">
              <div className="text-2xl lg:mt-5 mt-2 mb-5">
                Study options for {displayStudyModal.slice(0, 12)}
                {displayStudyModal.length > 12 ? "..." : ""}
              </div>
              <button
                disabled={!canStudySet(displayStudyModal)}
                className={
                  "text-xl flex flex-row justify-center mx-auto w-3/4 m-2 py-1 rounded-lg drop-shadow-lg" +
                  (canStudySet(displayStudyModal)
                    ? " bg-slate-700 hover:drop-shadow-lg hover:bg-slate-600"
                    : " disabled bg-slate-800")
                }
                onClick={() => {
                  if (canStudySet(displayStudyModal))
                    navigate(`/study-set/${displayStudyModal}`);
                }}
              >
                <div className="mt-[-1px] text-rose-600/[.75] drop-shadow-lg mr-2 scale-125">
                  <ViewCarousel />
                </div>
                Study Cards
              </button>
              {getCardTotalInSet(displayStudyModal) >= 20 && (
                <button
                  className="text-xl flex flex-row justify-center mx-auto w-3/4 hover:drop-shadow-lg hover:bg-slate-600 mt-2 py-1 rounded-lg bg-slate-700 drop-shadow-lg"
                  onClick={() =>
                    navigate(`/translation-practice/${displayStudyModal}`)
                  }
                >
                  <div className="mt-[-2px] text-rose-600/[.75] drop-shadow-lg mr-2 scale-125">
                    <Notes />
                  </div>
                  Translation Practice
                </button>
              )}
              <button
                className="text-xl flex flex-row justify-center mx-auto w-3/4 hover:drop-shadow-lg hover:bg-slate-600 m-2 mt-8 py-1 rounded-lg bg-slate-700 drop-shadow-lg"
                onClick={() => setDisplayStudyModal("")}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full h-full flex flex-col">
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="justify-center mt-5">
            <div className="text-3xl mb-3">Your Study Sets</div>
            <div className="">
              {studySets.length === 0 ? (
                <div className="m-2 text-xl">
                  Create or import a set to study
                </div>
              ) : (
                <div className="flex flex-col">{renderSetList()}</div>
              )}
            </div>
          </div>
          <div className="mt-auto justify-center my-auto">
            <button
              onClick={() => {
                navigate("/edit-set");
              }}
              className="w-44 mr-4 mb-5 mt-2 py-2 hover:drop-shadow-lg hover:bg-slate-600 rounded-lg bg-slate-700 drop-shadow-lg"
            >
              Create Set
            </button>
            <button
              onClick={() => {
                navigate("/import-set");
              }}
              className="w-44 mb-5 mt-2 py-2 rounded-lg hover:drop-shadow-lg hover:bg-slate-600 bg-slate-700 drop-shadow-lg"
            >
              Import Set
            </button>
          </div>
          {ConfirmDelete()}
          {StudyModal()}
        </>
      )}
    </div>
  );
}

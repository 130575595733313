import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import ePub, { Book, Contents, EpubCFI, Rendition } from "epubjs";
import "tailwindcss/tailwind.css";
import useDebouncedState from "../../shared/debounce";
import AddToSetComponent from "./add-to-set-modal";

interface Highlight {
  id: number;
  text: string;
  top: number;
  left: number;
  range: EpubCFI;
}

interface ContextMenu {
  highlight?: Highlight;
  x: number;
  y: number;
}

export interface SelectedRange {
  cfiRange: EpubCFI;
  contents: Contents;
}

export default function EpubReader() {
  const viewerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [book, setBook] = useState<Book | null>(null);
  const [rendition, setRendition] = useState<Rendition | null>(null);
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [contextMenu, setContextMenu] = useState<ContextMenu | null>(null);
  const [selectedRange, setSelectedRange] = useState<SelectedRange>();
  const selectedRangeDeb = useDebouncedState<SelectedRange | undefined>(
    selectedRange,
    250
  );
  const [addToSet, setAddToSet] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRange) {
      const range = selectedRange.contents.range(
        selectedRange.cfiRange.toString()
      );
      const rect = range.getBoundingClientRect();
      if (rect && rect.top !== 0 && rect.left !== 0) {
        setContextMenu({ x: rect.left + 25, y: rect.top + 25 });
      }
    }
  }, [selectedRangeDeb]);

  function highlightText(selectedRange: SelectedRange) {
    const range = selectedRange.contents.range(
      selectedRange.cfiRange.toString()
    );
    const rect = range.getBoundingClientRect();
    if (rect && rect.top !== 0 && rect.left !== 0) {
      const highlight: Highlight = {
        id: Date.now(),
        text: range.toString(),
        top: rect.top + window.scrollY,
        left: rect.left,
        range: selectedRange.cfiRange,
      };
      console.log(highlight);
      setHighlights([...highlights, highlight]);
      rendition?.annotations.highlight(
        selectedRange.cfiRange.toString(),
        {},
        (e: any) => {
          handleHighlightClick(highlight);
        },
        "",
        { fill: "rgba(255, 240, 0, 0.75)" }
      );
    }
    selectedRange.contents.window.getSelection()?.removeAllRanges();
    setContextMenu(null);
  }

  function handleAddToSet(selectedRange: SelectedRange) {
    highlightText(selectedRange);
    setAddToSet(true);
    selectedRange?.contents.window.getSelection()?.removeAllRanges();
    setContextMenu(null);
  }

  const handleSelection = (cfiRange: EpubCFI, contents: any) => {
    setSelectedRange({ cfiRange, contents });
  };

  const onReaderTextSelected = (cfirange: EpubCFI, contents: Contents) => {
    handleSelection(cfirange, contents);
  };

  const onReaderClick = () => {
    setContextMenu(null);
    setAddToSet(false);
  };

  useEffect(() => {
    let newRendition: Rendition;
    const epubBook = ePub();
    epubBook
      .open("/이상한선생님.epub")
      .then(() => {
        setBook((oldVal) => {
          if (oldVal === null) {
            newRendition = epubBook.renderTo(
              viewerRef.current as HTMLDivElement,
              { width: "100%", height: "100%" }
            );
            newRendition.themes.default({
              p: { "font-size": "large !important" },
            });
            newRendition.on("selected", onReaderTextSelected);
            newRendition.on("click", onReaderClick);
            newRendition.display();
            setRendition(newRendition);
            return epubBook;
          }
          return oldVal;
        });
      })
      .catch((err) => console.error("Error loading EPUB file:", err));

    return () => {
      if (epubBook) {
        epubBook.destroy();
      }
      if (newRendition) {
        newRendition.clear();
        newRendition.off("selected", onReaderTextSelected);
        newRendition.off("click", onReaderClick);
        newRendition.destroy();
      }
      const iframe = document.querySelector("iframe");
      if (iframe) {
        iframe.remove();
      }
    };
  }, []);

  const handleHighlightClick = (highlight: Highlight) => {
    setContextMenu({
      highlight,
      x: highlight.left + 25,
      y: highlight.top + 25,
    });
  };

  const handleRemoveHighlight = (highlight: Highlight) => {
    setHighlights(highlights.filter((h) => h.id !== highlight.id));
    rendition?.annotations.remove(highlight.range.toString(), "highlight");
    setContextMenu(null);
  };

  const handleNextPage = () => {
    rendition?.next();
  };

  const handlePrevPage = () => {
    rendition?.prev();
  };

  function handleCloseAddToSet() {
    setAddToSet(false);
  }

  const ContextMenu = () => {
    if (!contextMenu) return <></>;
    return (
      <div
        className="absolute bg-white border border-gray-300 rounded-lg shadow-md p-1 z-40"
        style={{ top: contextMenu.y, left: contextMenu.x }}
      >
        <div className="flex flex-row gap-x-1">
          {contextMenu.highlight ? (
            <button
              className="text-sm text-red-600"
              onClick={() => handleRemoveHighlight(contextMenu.highlight!!)}
            >
              Remove Highlight
            </button>
          ) : (
            <button
              className="text-sm text-cyan-800"
              onClick={() => highlightText(selectedRange!!)}
            >
              Add Highlight
            </button>
          )}
          <div className="w-[1px] bg-slate-900"></div>
          <button
            className="text-sm text-cyan-800"
            onClick={() => handleAddToSet(selectedRange!!)}
          >
            Add To Set
          </button>
          <div className="w-[1px] bg-slate-900"></div>
          <button
            className="text-sm text-cyan-800"
            onClick={() => {
              console.warn("NOT YET IMPLEMENTED!");
            }}
          >
            Translate
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full h-screen bg-slate-800 p-4">
      <div
        ref={viewerRef}
        className="border border-gray-300 rounded-lg shadow-lg drop-shadow-lg bg-slate-200 w-full h-[70vh] md:h-[77vh] : overflow-auto p-4"
      />
      <div className="flex justify-between mt-2">
        <button
          className="rounded-lg bg-slate-700 drop-shadow-lg hover:drop-shadow-lg hover:bg-slate-600 px-4 py-2 w-[30%]"
          onClick={handlePrevPage}
        >
          Previous Page
        </button>
        <button
          className="rounded-lg bg-slate-700 hover:drop-shadow-lg hover:bg-slate-600 px-4 py-2 w-[30%]"
          onClick={handleNextPage}
        >
          Next Page
        </button>
      </div>

      {contextMenu && <ContextMenu />}
      <AddToSetComponent
        selectedRange={selectedRange}
        display={addToSet}
        handleClose={handleCloseAddToSet}
      />
    </div>
  );
}

import { CircularProgress } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SetService } from "../../services/set-service";
import { SetTemplate, Confidence } from "../../templates/set-template";

enum StudyMode {
  flashCard,
  multipleChoice,
  input,
}

enum StudyVal {
  primaryVal,
  secondaryVal,
}

export default function StudySet() {
  const { setName } = useParams();
  const [studyMode, setStudyMode] = useState<StudyMode>(StudyMode.flashCard);
  const [setData, setSetData] = useState<SetTemplate[]>([]);
  const [dataToStudy, setDataToStudy] = useState<SetTemplate[]>([]);
  const [dataToStudyConst, setDataToStudyConst] = useState<SetTemplate[]>([]);
  const [activeCard, setActiveCard] = useState<SetTemplate>();
  const [incorrectData, setIncorrectData] = useState<SetTemplate[]>([]);
  const [incorrectAnsTotal, setIncorrectAnsTotal] = useState<number>(1);
  const [progressDenom, setProgressDenom] = useState<number>(1);
  const [progressVal, setProgressVal] = useState<number>(0);
  const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const [flashCardAnswer, setFlashcardAnswer] = useState<string>("");
  const [mcAnswer, setMcAnswer] = useState<string[]>([]);
  const [currentCardIndex, setCurrentCardIndex] = useState<number>(0);
  const [questionVal, setQuestionVal] = useState<StudyVal>(StudyVal.primaryVal);
  const [doneStudying, setDoneStudying] = useState<boolean>(false);
  const [awaitConfidenceInput, setAwaitConfidenceInput] =
    useState<boolean>(false);
  const [answerInput, setAnswerInput] = useState<string>("");
  const [incorrectInput, setIncorrectInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const setService = useMemo(() => new SetService(), []);
  const navigate = useNavigate();

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (!awaitConfidenceInput && event.key === "Enter")
      onInputClick(answerInput);
    if (event.key === " " && studyMode === StudyMode.flashCard) onCardClick();
    if (!awaitConfidenceInput || !["1", "2", "3"].includes(event.key)) return;
    let confidence =
      event.key === "1"
        ? Confidence.LOW
        : event.key === "2"
        ? Confidence.MEDIUM
        : Confidence.HIGH;
    onDifficultyClick(confidence);
  };

  function updateActiveCard(
    card: SetTemplate,
    question: string | null,
    answer: string | null,
    mode: StudyMode
  ) {
    setActiveCard(card);
    setQuestion(question ?? "");
    setFlashcardAnswer(answer ?? "");
    if (mode === StudyMode.multipleChoice) {
      setMcAnswer(getRandomMCAnswers(answer ?? "", questionVal));
    }
  }

  useEffect(() => {
    function compareCardDates(a: SetTemplate, b: SetTemplate): number {
      if (a.dateToStudy === undefined) return 1;
      else if (b.dateToStudy === undefined) return -1;
      else if (a.dateToStudy < b.dateToStudy) return -1;
      else if (a.dateToStudy > b.dateToStudy) return 1;
      else return 0;
    }

    if (setName !== undefined && dataToStudyConst.length === 0) {
      const set = setService.getSet(setName);
      setSetData(set);
      let counter = 0;
      const tempDataToStudy = set
        .sort((a, b) => {
          return compareCardDates(a, b);
        })
        .filter((item) => {
          if (
            counter >= setService.getMaxStudyAmount() ||
            (item?.dateToStudy && item.dateToStudy > setService.getDateString())
          )
            return false;
          counter++;
          return true;
        });
      setDataToStudyConst(tempDataToStudy);
      setDataToStudy(shuffleArray(tempDataToStudy));
      if (tempDataToStudy.length > 0) {
        setActiveCard(tempDataToStudy[0]);
        setQuestion(tempDataToStudy[0].primaryVal ?? "");
        setFlashcardAnswer(tempDataToStudy[0].secondaryVal ?? "");
      }
      if (tempDataToStudy?.length !== 0) {
        setProgressVal(progressDenom / tempDataToStudy.length);
      }
      setLoading(false);
    }
  }, [setName, progressDenom, setService, studyMode, dataToStudyConst.length]);

  function shuffleArray(array: any[]) {
    var m = array.length,
      t,
      i;
    while (m) {
      i = Math.floor(Math.random() * m--);
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  }

  function getRandomMCAnswers(answer: string, studyVal: StudyVal): string[] {
    let arr = [];
    let answerIndex = Math.floor(Math.random() * 4);
    while (arr.length < 3) {
      let r = Math.floor(Math.random() * setData.length);
      let val =
        studyVal === StudyVal.secondaryVal
          ? setData[r].primaryVal
          : setData[r].secondaryVal;
      if (arr.indexOf(r) === -1 && val !== answer) arr.push(r);
    }
    let out = [];
    for (let i = 0; i < 4; i++) {
      if (answerIndex === i) {
        out.push(answer);
      } else if (answerIndex < i) {
        let val =
          studyVal === StudyVal.secondaryVal
            ? setData[arr[i - 1]].primaryVal
            : setData[arr[i - 1]].secondaryVal;
        out.push(val ?? "");
      } else {
        let val =
          studyVal === StudyVal.secondaryVal
            ? setData[arr[i]].primaryVal
            : setData[arr[i]].secondaryVal;
        out.push(val ?? "");
      }
    }
    return out;
  }

  function nextActiveCard() {
    let active: SetTemplate;
    let tempStudyMode = studyMode;
    setProgressDenom(progressDenom + 1);
    setProgressVal(
      ((progressDenom + 1) / (dataToStudyConst.length + incorrectAnsTotal)) *
        100
    );
    if (
      currentCardIndex + 1 < dataToStudy.length &&
      currentCardIndex + 1 >= 0
    ) {
      active = dataToStudy[currentCardIndex + 1];
      setCurrentCardIndex(currentCardIndex + 1);
    } else if (incorrectData.length !== 0) {
      let newData = shuffleArray(incorrectData);
      setDataToStudy(newData);
      active = newData[0];
      setIncorrectData([]);
      setCurrentCardIndex(0);
    } else {
      setIncorrectAnsTotal(0);
      setProgressDenom(0);
      setProgressVal(1);
      let newData = shuffleArray(dataToStudyConst);
      setDataToStudy(newData);
      setIncorrectData([]);
      active = newData[0];
      if (studyMode === StudyMode.flashCard) {
        if (setData.length >= 4) {
          tempStudyMode = StudyMode.multipleChoice;
        } else {
          tempStudyMode = StudyMode.input;
        }
        setStudyMode(tempStudyMode);
      } else if (studyMode === StudyMode.multipleChoice) {
        tempStudyMode = StudyMode.input;
        setStudyMode(tempStudyMode);
      } else if (studyMode === StudyMode.input) {
        if (questionVal === StudyVal.primaryVal) {
          tempStudyMode = StudyMode.flashCard;
          setQuestionVal(StudyVal.secondaryVal);
          setStudyMode(tempStudyMode);
          setCurrentCardIndex(0);
          updateActiveCard(
            active,
            active.secondaryVal,
            active.primaryVal,
            tempStudyMode
          );
          return;
        } else {
          setDoneStudying(true);
        }
      }
      setCurrentCardIndex(0);
    }
    if (questionVal === StudyVal.primaryVal) {
      updateActiveCard(
        active,
        active.primaryVal,
        active.secondaryVal,
        tempStudyMode
      );
    } else {
      updateActiveCard(
        active,
        active.secondaryVal,
        active.primaryVal,
        tempStudyMode
      );
    }
  }

  function onCardClick() {
    if (studyMode !== StudyMode.flashCard) return;
    if (displayAnswer) {
      nextActiveCard();
    }
    setDisplayAnswer(!displayAnswer);
  }

  function onMCClick(clicked: string) {
    if (clicked === flashCardAnswer) {
      setDisplayAnswer(false);
      nextActiveCard();
    } else {
      setIncorrectAnsTotal(incorrectAnsTotal + 1);
      setDisplayAnswer(true);
      if (activeCard) setIncorrectData([...incorrectData, activeCard]);
    }
  }

  function isAnswerCorrect(input: string, correct: string): boolean {
    let output = false;
    if (input.toLocaleLowerCase() === correct.toLocaleLowerCase()) return true;
    else {
      let splitCorrect: string[] = [];
      correct
        .split(",")
        .forEach((s) =>
          splitCorrect.push(s.trimStart().trimEnd().toLocaleLowerCase())
        );
      if (correct.includes("(") || correct.includes(")")) {
        correct.split("(").forEach((s) => {
          if (!s.includes(")"))
            splitCorrect.push(s.trimStart().trimEnd().toLocaleLowerCase());
        });
      }
      splitCorrect.push(
        correct
          .trimStart()
          .trimEnd()
          .toLocaleLowerCase()
          .replaceAll(".", "")
          .replaceAll(";", "")
          .replaceAll("/", " ")
      );
      splitCorrect.forEach((s) => {
        if (input.toLocaleLowerCase() === s) output = true;
      });
    }
    return output;
  }

  function onInputClick(inputedAnswer: string, override: boolean = false) {
    if (override) {
      setIncorrectAnsTotal(incorrectAnsTotal - 1);
      setIncorrectData(incorrectData.slice(0, incorrectData.length - 1));
    }
    if (isAnswerCorrect(inputedAnswer, flashCardAnswer) || override) {
      setDisplayAnswer(false);
      if (
        questionVal === StudyVal.secondaryVal &&
        (!incorrectData.includes(activeCard ?? dataToStudy[0]) || override)
      ) {
        setAwaitConfidenceInput(true);
      } else {
        nextActiveCard();
      }
    } else {
      setIncorrectAnsTotal(incorrectAnsTotal + 1);
      setDisplayAnswer(true);
      setIncorrectInput(inputedAnswer);
      if (activeCard) setIncorrectData([...incorrectData, activeCard]);
    }
    setAnswerInput("");
  }

  function setNewStudyDate(
    setName: string,
    card: SetTemplate | undefined,
    confidence: Confidence
  ) {
    if (card === undefined) return;
    let date = setService.getNextDate(
      confidence,
      card.dateToStudy ?? setService.getDateString(),
      card.oldDate
    );
    console.log(
      "Setting new date",
      card.primaryVal,
      card.secondaryVal,
      date,
      "old date:",
      card.dateToStudy
    );
    setService.updateCardDate(setName, card, date);
  }

  function onDifficultyClick(confidence: Confidence) {
    if (!awaitConfidenceInput) return;
    setNewStudyDate(setName ?? "", activeCard, confidence);
    nextActiveCard();
    setAwaitConfidenceInput(false);
  }

  return (
    <div className="h-screen" onKeyUp={handleKeyPress}>
      <button
        className="absolute top-20 sm:left-32 left-5 w-10 sm:w-36 py-2 rounded-lg bg-rose-600/[.75] drop-shadow-lg"
        onClick={() => navigate("/")}
      >
        <div className="flex flex-row justify-center sm:mr-5">
          <ChevronLeft></ChevronLeft>
          <span className="sm:visible invisible w-0 sm:w-auto">Back</span>
        </div>
      </button>
      {loading ? (
        <div className="flex flex-row py-5 justify-center">
          <CircularProgress />
          <div className="text-5xl ml-2">Loading...</div>
        </div>
      ) : (
        setName !== "" &&
        setName !== undefined && (
          <div className="flex flex-col justify-center h-full">
            <div className="w-1/2 justify-self-center self-center text-4xl mt-16 sm:mt-5 sm:mb-10 mb-5">
              <div>
                Set: {setName.slice(0, 25)}
                {setName.length > 25 ? "..." : ""}
              </div>
            </div>
            <div className="h-2.5 bg-black/[.7] w-1/2 mx-auto mb-5 rounded-xl">
              <div
                className="bg-rose-600 h-2.5 rounded-xl"
                style={{ width: (progressVal > 1 ? progressVal : 1) + "%" }}
              ></div>
            </div>
            <div
              className="h-1/2 lg:w-1/2 w-full bg-gray-700/[.4] rounded mb-auto mx-auto drop-shadow-lg"
              onClick={() => onCardClick()}
            >
              {!doneStudying ? (
                <div className="flex flex-col justify-center">
                  <div className="text-5xl my-10">{question}</div>
                  {studyMode === StudyMode.flashCard && displayAnswer && (
                    <>
                      <div className="w-1/2 h-0.5 mx-auto bg-rose-600"></div>
                      <div className="text-4xl sm:mb-5 mt-10 mb-5">
                        {flashCardAnswer}
                      </div>
                    </>
                  )}
                  {studyMode === StudyMode.multipleChoice ? (
                    <div className="flex md:flex-row flex-col m-auto md:my-5 w-10/12 justify-center">
                      <div className="flex flex-col md:w-5/12 w-2/3 md:mx-1 mx-auto">
                        <div
                          className="cursor-pointer w-full p-1 m-1 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                          onClick={() => onMCClick(mcAnswer[0])}
                        >
                          {mcAnswer[0]}
                        </div>
                        <div
                          className="cursor-pointer w-full p-1 m-1 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                          onClick={() => onMCClick(mcAnswer[2])}
                        >
                          {mcAnswer[2]}
                        </div>
                      </div>
                      <div className="flex flex-col md:w-5/12 w-2/3 md:mx-1 mx-auto">
                        <div
                          className="cursor-pointer w-full p-1 m-1 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                          onClick={() => onMCClick(mcAnswer[1])}
                        >
                          {mcAnswer[1]}
                        </div>
                        <div
                          className="cursor-pointer w-full p-1 m-1 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                          onClick={() => onMCClick(mcAnswer[3])}
                        >
                          {mcAnswer[3]}
                        </div>
                      </div>
                    </div>
                  ) : (
                    studyMode === StudyMode.input && (
                      <div>
                        <input
                          autoFocus={studyMode === StudyMode.input}
                          className="w-1/2 rounded text-lg text-black p-1 drop-shadow-lg"
                          disabled={awaitConfidenceInput}
                          placeholder="Answer"
                          value={answerInput}
                          onChange={(event) =>
                            setAnswerInput(event.target.value)
                          }
                          type="text"
                        />
                        <div className="">
                          <button
                            className="md:w-1/3 w-5/12 p-1 m-1 mt-2 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                            onClick={() => onInputClick(answerInput)}
                            type="button"
                          >
                            Submit
                          </button>
                        </div>
                        {questionVal === StudyVal.secondaryVal &&
                          awaitConfidenceInput && (
                            <div className="flex flex-wrap mt-5 justify-center">
                              <div
                                onClick={() =>
                                  onDifficultyClick(Confidence.LOW)
                                }
                                className="cursor-pointer w-28 m-1 p-1 bg-rose-800/[.6] rounded drop-shadow-lg"
                              >
                                1. Hard
                              </div>
                              <div
                                onClick={() =>
                                  onDifficultyClick(Confidence.MEDIUM)
                                }
                                className="cursor-pointer w-28 m-1 p-1 bg-rose-800/[.6] rounded drop-shadow-lg"
                              >
                                2. Medium
                              </div>
                              <div
                                onClick={() =>
                                  onDifficultyClick(Confidence.HIGH)
                                }
                                className="cursor-pointer w-28 m-1 p-1 bg-rose-800/[.6] rounded drop-shadow-lg"
                              >
                                3. Easy
                              </div>
                            </div>
                          )}
                      </div>
                    )
                  )}
                  {studyMode !== StudyMode.flashCard && displayAnswer && (
                    <>
                      <div
                        className={
                          "text-2xl mt-10" +
                          (studyMode === StudyMode.input ? " mb-1" : " mb-5")
                        }
                      >
                        <span className="text-rose-600/[.9]">
                          Correct Answer:{" "}
                        </span>
                        {flashCardAnswer}
                      </div>
                      {studyMode === StudyMode.input && (
                        <>
                          <div className="text-xl">
                            <span className="text-rose-600/[.9]">
                              Your Answer:{" "}
                            </span>
                            {incorrectInput}
                          </div>
                          <div
                            onClick={() => onInputClick(incorrectInput, true)}
                            className="cursor-pointer mx-auto w-3/12 p-0.5 mb-5 mt-2 bg-rose-700/[.5] rounded drop-shadow-lg"
                          >
                            I Was Right
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="text-3xl my-5">Done Studying</div>
                  <button
                    className="md:w-1/3 w-7/12 p-1 mx-auto mt-2 bg-rose-600/[.6] rounded text-lg drop-shadow-lg"
                    onClick={() => navigate("/home")}
                    type="button"
                  >
                    Exit
                  </button>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
}

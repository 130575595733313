import { useEffect, useMemo, useState } from "react";
import { SelectedRange } from "./epub-reader";
import { SetService } from "../../../services/set-service";
import { SetTemplate } from "../../../templates/set-template";

interface AddToSetComponentProps {
  selectedRange: SelectedRange | undefined;
  display: boolean;
  handleClose: () => void;
}

export default function AddToSetComponent({
  selectedRange,
  display,
  handleClose: _handleClose,
}: AddToSetComponentProps) {
  const [value, setValue] = useState<string>("");
  const [def, setDef] = useState<string>("");
  const [set, setSet] = useState<string>("");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const setService = useMemo(() => new SetService(), []);
  const setNames = setService.getSetNames();

  useEffect(() => {
    if (selectedRange) {
      const range = selectedRange.contents.range(
        selectedRange.cfiRange.toString()
      );
      setValue(range.toString().trim());
    }
  }, [selectedRange]);

  useEffect(() => {
    setButtonDisabled(def === "" || value === "" || set === "" || set === "");
    console.log(value, def, set);
  }, [value, def, set]);

  function clearValues() {
    setSet("");
    setDef("");
    setValue("");
  }

  function handleClose() {
    clearValues();
    _handleClose();
  }

  function handleAddToSet() {
    const newCard: SetTemplate = {
      primaryVal: value,
      secondaryVal: def,
    };
    setService.addCard(set, newCard);
    handleClose();
  }

  if (!selectedRange || !display) return <></>;
  return (
    <div className="z-50 bg-black bg-opacity-60 absolute w-full h-full top-0 left-0" onClick={handleClose}>
      <div onClick={(event) => {event.stopPropagation()}} className="absolute top-1/4 lg:left-1/3 lg:right-1/3 left-10 right-10 h-fit lg:w-1/3 sm:w-1/2 z-50 m-auto bg-slate-900 rounded-xl flex flex-col drop-shadow-2xl p-4">
        <p className="self-start font-semibold">Set</p>
        <select
          onChange={(event: any) => {
            console.log(event);
            setSet(event.target.value);
          }}
          className="bg-slate-600 text-white px-1 py-2 rounded"
          defaultValue={""}
        >
          <option key="" className="text-slate-600" value="">
            {setNames.length > 0 ? "Select" : "Create a set..."}
          </option>
          {setNames.map((set) => (
            <option key={set.setName} value={set.setName}>
              {set.setName}
            </option>
          ))}
        </select>
        <p className="self-start font-semibold mt-2">Value</p>
        <input
          className="bg-slate-600 text-white p-1 rounded"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <p className="self-start font-semibold mt-2">Definition</p>
        <input
          className="bg-slate-600 text-white p-1 rounded"
          value={def}
          onChange={(event) => setDef(event.target.value)}
        />
        <button
          className="disabled:text-red-600 disabled:cursor-default cursor-pointer rounded-lg bg-slate-700 drop-shadow-lg mt-4 px-4 mx-auto py-2 w-1/2"
          onClick={handleAddToSet}
          disabled={buttonDisabled}
        >
          Add To Set
        </button>
      </div>
    </div>
  );
}
